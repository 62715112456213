import api from "../../../../services/api";

import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import * as globalComponents from "../../../../components";
import TributesEntry from "./components/TributesEntry";

import "../styles.css";
import "./styles.css";

const initialOptions = {
  multiplos: "Multiplos Homenageados",
  a: "A",
  b: "B",
  c: "C",
  d: "D",
  e: "E",
  f: "F",
  g: "G",
  h: "H",
  i: "I",
  j: "J",
  k: "K",
  l: "L",
  m: "M",
  n: "N",
  o: "O",
  p: "P",
  q: "Q",
  r: "R",
  s: "S",
  t: "T",
  u: "U",
  v: "V",
  w: "W",
  x: "X",
  y: "Y",
  z: "Z",
};

export default function Tributes() {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  const { accessType } = useParams();
  const [emptyTributes, setEmptyTributes] = useState(false);
  const [initial, setInitial] = useState(
    query.get("inicial") === null
      ? "multiplos"
      : query.get("inicial").toLowerCase()
  );
  const [publications, setPublications] = useState([]);

  useEffect(() => {
    if (emptyTributes) {
      document.getElementById("empty_tribute").removeAttribute("hidden");
    } else if (!emptyTributes) {
      document.getElementById("empty_tribute").setAttribute("hidden", "true");
    }
  }, [emptyTributes]);

  useEffect(() => {
    window.scroll(0, 0);
    let queryParams = new URLSearchParams(window.location.search);
    queryParams.set("inicial", initial);
    window.history.replaceState(null, null, "?" + queryParams.toString());
    document.getElementById("initial").value = initial;
    async function fetchPublications() {
      await api
        .get(`/homenagens?inicial=${initial}`)
        .then((response) => {
          setEmptyTributes(false);
          setPublications(response.data);
        })
        .catch((err) => {
          setEmptyTributes(true);
          setPublications([]);
          console.log(err);
        });
    }
    fetchPublications();
  }, [initial]);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title={"Homenagens"} dir={[]} />

      <div className="container">
        <div className="title__publications">
          <div className="tribute_header">
            <strong>
              Homenagens - {initialOptions[initial].split(" ").shift()}
            </strong>
            <div className="initial_select">
              <label htmlFor="initial">Nome: </label>
              <select
                name="initial"
                id="initial"
                onChange={(e) => setInitial(e.target.value)}
              >
                {Object.keys(initialOptions).map((key) => (
                  <option key={key} value={key}>
                    {initialOptions[key]}
                  </option>
                ))}
              </select>
            </div>
            <div className="initials_buttons">
              {Object.keys(initialOptions).map((key) => (
                <button
                  key={key}
                  className={initial === key ? "active" : ""}
                  onClick={(e) => {
                    e.preventDefault();
                    setInitial(key);
                  }}
                >
                  {initialOptions[key].split(" ").shift()}
                </button>
              ))}
            </div>
          </div>

          <div id="empty_tribute" className="empty_tribute" hidden>
            <h3>Registros Não Encontrados!</h3>
            <p>Não existem registros para a inicial selecionada.</p>
          </div>

          <div className="tributes_grid">
            {publications.map((value, index) => (
              <>
                <TributesEntry
                  key={index}
                  name={value.Nome_Homenageado}
                  title={value.Cargo_Homenageado}
                  href={`/${accessType}/conteudo/${value.Materia}`}
                  imgURL={value.Nome_Arquivo}
                />
                {index % 4 !== 3 && index !== publications.length - 1 ? (
                  <div className="divider"></div>
                ) : null}
              </>
            ))}
          </div>
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
