import React from "react";

import * as globalComponents from "../../../../../components";
import UnipersonalLimitedSocietyDiscountResolutionForm from "./components/UnipersonalLimitedSocietyDiscountResolutionForm";

import "../../styles.css";

export default function UnipersonalLimitedSocietyDiscountResolution() {
  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs
        title="Solicitação de Isenção de Anuidades por Sociedade Limitada Unipessoal ou Empresa Individual"
        dir={[]}
      />

      <div className="container">
        <globalComponents.PageTitle title="FORMULÁRIO PARA SOLICITAÇÃO DA ISENÇÃO DE ANUIDADE PESSOA FÍSICA, EM RAZÃO POR PROFISSIONAL FISIOTERAPEUTA E/OU TERAPEUTA OCUPACIONAL SÓCIO DE SOCIEDADE LIMITADA UNIPESSOAL OU EMPRESA INDIVIDUAL" />
        <p>
          Artigo 7º, § 3º da{" "}
          <a
            href="https://www.coffito.gov.br/nsite/?p=30151"
            style={{ color: "var(--secondary-color)" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Resolução COFFITO Nº 598/2024
          </a>
          .
        </p>
        <div className="denuncia--container__wrapper">
          <div className="denuncia--content">
            <UnipersonalLimitedSocietyDiscountResolutionForm />
          </div>
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
