import api from "../../../../../../../services/api";

import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";

import "./styles.css";

export default function SicknessBasedDiscountResolutionForm() {
  const [formFiles, setFormFiles] = useState(null);
  const [cep, setCep] = useState("");
  const [cepInfo, setCepInfo] = useState({
    cep: "",
    logradouro: "",
    complemento: "",
    bairro: "",
    localidade: "",
    uf: "",
    ibge: "",
    gia: "",
    ddd: "",
    siafi: "",
  });
  const { register, handleSubmit } = useForm();

  const cleanForm = useCallback(() => {
    document.getElementById("cep").value = "";
    document.getElementById("place").value = "";
    document.getElementById("neighbourhood").value = "";
    document.getElementById("city").value = "";
    document.getElementById("uf").value = "";
    disableAddress();
  }, []);

  useEffect(() => {
    async function fetchCepInfo() {
      if (cep !== "") {
        await fetch(`https://viacep.com.br/ws/${cep}/json/`)
          .then((res) => res.json())
          .then((apiCepInfo) => {
            setCepInfo(apiCepInfo);
          });
      }
    }
    fetchCepInfo();
  }, [cep]);

  useEffect(() => {
    function fillForm() {
      document.getElementById("place").value = cepInfo.logradouro;
      document.getElementById("neighbourhood").value = cepInfo.bairro;
      document.getElementById("uf").value = cepInfo.uf;
      document.getElementById("city").value = cepInfo.localidade;
    }
    if (!cepInfo.erro) {
      fillForm();
    } else {
      cleanForm();
      alert("CEP inválido. Por favor, digite um CEP válido");
    }
  }, [cepInfo, cleanForm]);

  function enableAddress() {
    document.getElementById("place").removeAttribute("disabled");
    document.getElementById("neighbourhood").removeAttribute("disabled");
    document.getElementById("city").removeAttribute("disabled");
    document.getElementById("uf").removeAttribute("disabled");
    document.getElementById("number").removeAttribute("disabled");
    document.getElementById("complement").removeAttribute("disabled");
  }

  function disableAddress() {
    document.getElementById("place").setAttribute("disabled", true);
    document.getElementById("neighbourhood").setAttribute("disabled", true);
    document.getElementById("city").setAttribute("disabled", true);
    document.getElementById("uf").setAttribute("disabled", true);
    document.getElementById("number").setAttribute("disabled", true);
    document.getElementById("complement").setAttribute("disabled", true);
  }

  function DisableFileUpload() {
    const submit = document.getElementById("submit");

    submit.value = "Carregando Arquivos...";
    submit.classList.add("disabled");
    submit.setAttribute("disabled", true);
  }

  function EnableFileUpload() {
    const submit = document.getElementById("submit");

    submit.value = "Enviar";
    submit.classList.remove("disabled");
    submit.removeAttribute("disabled");
  }

  function ClearFileUpload() {
    document.getElementById("files").value = null;
  }

  async function onSubmit(data) {
    const submit = document.getElementById("submit");

    data.files = formFiles;
    submit.value = "Enviando...";
    submit.classList.add("disabled");
    submit.setAttribute("disabled", true);

    try {
      const result = await api.post(
        "/form/desconto-anuidade/isencao-portadores-de-doencas-graves",
        data
      );
      if (!alert(result.data.message)) {
        window.scroll(0, 0);
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
      if (!alert("Ocorreu um erro! Tente novamente mais tarde.")) {
        window.scroll(0, 0);
        submit.value = "Enviar";
        submit.classList.remove("disabled");
        submit.removeAttribute("disabled");
      }
    }
  }

  return (
    <div className="denuncia__form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form__title">
          <p>
            Formulário para Solicitação de Isenção de Anuidades para Portadores
            de Doenças Graves
          </p>
        </div>

        <div className="form__options">
          <div className="option__column">
            <p>Selecione sua categoria</p>
            <ul>
              <li>
                <input
                  {...register("profile", { required: true })}
                  type="radio"
                  value="fisioterapia"
                />
                Fisioterapia
              </li>
              <li>
                <input
                  {...register("profile", { required: true })}
                  type="radio"
                  value="terapia ocupacional"
                />
                Terapia Ocupacional
              </li>
            </ul>
          </div>
        </div>

        <div className="form__divisor"></div>

        <div className="form__group">
          <input
            {...register("register", { required: true })}
            type="text"
            placeholder="Número de registro"
          />
          <input
            {...register("name", { required: true })}
            type="text"
            placeholder="Nome completo"
          />
          <div className="form__group--half">
            <input
              {...register("email", { required: true })}
              type="email"
              placeholder="Endereço de E-Mail"
            />
            <input
              {...register("cel", { required: true })}
              type="tel"
              placeholder="Telefone"
            />
          </div>
        </div>

        <div className="form__divisor"></div>

        <div className="form__group">
          <label htmlFor="cep">CEP:</label>
          <input
            id="cep"
            {...register("cep", { required: true })}
            type="number"
            placeholder="CEP"
            onBlur={(e) => {
              var cep = e.target.value.replace(/\D/g, "");

              if (cep !== null && cep !== "") {
                var cepRegex = /^[0-9]{8}$/;

                if (cepRegex.test(cep)) {
                  enableAddress();
                  setCep(cep);
                } else {
                  cleanForm();
                  alert("Formato de CEP inválido.");
                }
              } else {
                cleanForm();
              }
            }}
          />
          <label htmlFor="place">Endereço:</label>
          <input
            id="place"
            {...register("place", { required: true })}
            type="text"
            placeholder="Logradouro"
            disabled
          />
          <div className="form__group--half">
            <input
              id="number"
              {...register("number", { required: true })}
              type="text"
              placeholder="Número"
              disabled
            />
            <input
              id="complement"
              {...register("complement")}
              type="text"
              placeholder="Complemento"
              disabled
            />
          </div>
          <input
            id="neighbourhood"
            {...register("neighbourhood", { required: true })}
            type="text"
            placeholder="Bairro"
            disabled
          />
          <div className="form__group--half">
            <input
              id="uf"
              {...register("uf", { required: true })}
              type="text"
              defaultValue=""
              placeholder="Estado (UF)"
              disabled
            />
            <input
              id="city"
              {...register("city", { required: true })}
              type="text"
              defaultValue=""
              placeholder="Cidade"
              disabled
            />
          </div>
          <div className="form-group files">
            <label htmlFor="files">Envio de arquivo:</label>

            <p className="form__observation">
              <strong>Observação:</strong> o envio de arquivos é obrigatório. Em
              caso de múltiplos arquivos, envie uma pasta ".zip" contendo todos.
            </p>

            <input
              id="files"
              {...register("files", { required: true })}
              type="file"
              className="form-control"
              multiple=""
              onChange={async function uploadFiles(e) {
                const uploadData = new FormData();

                DisableFileUpload();
                uploadData.append(
                  `file`,
                  e.target.files[0],
                  e.target.files[0].name
                );

                if (e.target.files[0].size < 90000000) {
                  try {
                    const result = await api.post("/upload", uploadData);
                    if (result.data.success) {
                      setFormFiles(result.data.uploadedFiles);
                      EnableFileUpload();
                    } else if (result.data.message.code) {
                      alert(result.data.message.code);
                      ClearFileUpload();
                      EnableFileUpload();
                    }
                  } catch (err) {
                    if (
                      !alert("Ocorreu um erro! Tente novamente mais tarde.")
                    ) {
                      window.scroll(0, 0);
                      ClearFileUpload();
                      EnableFileUpload();
                    }
                  }
                } else {
                  alert("Arquivo deve ser menor que 90 MB!");
                  ClearFileUpload();
                  EnableFileUpload();
                }
              }}
            />
          </div>
          <p>
            <br />
            Venho requerer{" "}
            <strong>
              ISENÇÃO DE PAGAMENTO DE ANUIDADES PARA PORTADORES DE DOENÇAS
              GRAVES
            </strong>
            , nos termos da{" "}
            <a
              href="https://www.coffito.gov.br/nsite/?p=6209"
              style={{ color: "var(--secondary-color)" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              RESOLUÇÃO COFFITO Nº. 472 de 20 de dezembro de 2016,
            </a>
            , por me enquadrar nos requisitos necessários conforme disposto no
            artigo 1º.
          </p>
          <div className="user_authorization">
            <input
              id="userRequirement"
              {...register("userRequirement", { required: true })}
              type="checkbox"
              value="eligible"
            />
            <label htmlFor="userRequirement">
              Sendo portador de doença grave, prevista em{" "}
              <a
                href="https://www.gov.br/receitafederal/pt-br/assuntos/orientacao-
              tributaria/isencoes/irpf/molestia-grave"
                style={{ color: "var(--secondary-color)" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Instrução Normativa da Receita Federal do Brasil Nº 1756, DE 31
                DE OUTUBRO DE 2017
              </a>
              .
              <br />
              <strong>RESOLVE:</strong>
              <br />
              <strong>§ 1º</strong>{" "}
              <u>
                Para tanto, apresento o <strong>LAUDO MÉDICO PERICIAL</strong>{" "}
                em anexo, emitido por serviço médico oficial da União, dos
                Estados, do Distrito Federal e dos Municípios, devendo ser
                fixado o prazo de validade do laudo pericial, no caso de doenças
                passíveis de controle, em conformidade com a legislação em
                vigor.
              </u>
              <br />
              <strong>§ 2º</strong> A isenção será válida enquanto durar a
              doença,{" "}
              <u>
                devendo a comprovação ser feita ao CREFITO-2 anualmente pelo
                profissional até a efetiva cura.
              </u>
            </label>
          </div>
          <div className="user_authorization">
            <input
              id="firstUserAuthorization"
              {...register("firstUserAuthorization", { required: true })}
              type="checkbox"
              value="authorized"
            />
            <label htmlFor="firstUserAuthorization">
              Autorizo o envio de informações para meu e-mail.
            </label>
          </div>
          <div className="user_authorization">
            <input
              id="secondUserAuthorization"
              {...register("secondUserAuthorization", { required: true })}
              type="checkbox"
              value="authorized"
            />
            <label htmlFor="secondUserAuthorization">
              Declaro, para fins de direito, sob as penas da lei, que as
              informações apresentadas são verdadeiras e autênticas.
            </label>
          </div>
          <input id="submit" className="button" type="submit" value="Enviar" />
        </div>
      </form>
    </div>
  );
}
