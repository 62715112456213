import api from "../../../../../../../services/api";

import React from "react";
import { useForm } from "react-hook-form";

import "./styles.css";

export default function UnipersonalLimitedSocietyDiscountResolutionForm() {
  const { register, handleSubmit } = useForm();

  async function onSubmit(data) {
    try {
      const result = await api.post(
        "/form/desconto-anuidade/isencao-sociedade-limitada-unipessoal",
        data
      );
      if (!alert(result.data.message)) {
        window.scroll(0, 0);
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
      if (!alert("Ocorreu um erro! Tente novamente mais tarde.")) {
        window.scroll(0, 0);
        window.location.reload();
      }
    }
  }

  return (
    <div className="denuncia__form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form__title">
          <p>
            Formulário para Solicitação da Isenção de Anuidade Pessoa Física, em
            Razão por Profissional Fisioterapeuta e/ou Terapeuta Ocupacional
            Sócio de Sociedade Limitada Unipessoal ou Empresa Individual
          </p>
        </div>

        <div className="form__options">
          <div className="option__column">
            <p>Selecione sua categoria</p>
            <ul>
              <li>
                <input
                  {...register("profile", { required: true })}
                  type="radio"
                  value="fisioterapia"
                />
                Fisioterapia
              </li>
              <li>
                <input
                  {...register("profile", { required: true })}
                  type="radio"
                  value="terapia ocupacional"
                />
                Terapia Ocupacional
              </li>
            </ul>
          </div>
        </div>

        <div className="form__divisor"></div>

        <div className="form__group">
          <p>Dados da pessoa física</p>

          <input
            {...register("personName", { required: true })}
            type="text"
            placeholder="Nome completo"
          />
          <input
            {...register("personRegister", { required: true })}
            type="text"
            placeholder="Número de registro de pessoa física"
          />
          <input
            {...register("personEmail", { required: true })}
            type="email"
            placeholder="Endereço de E-Mail"
          />
        </div>

        <div className="form__divisor"></div>

        <div className="form__group">
          <p>
            Dados da pessoa jurídica (Sociedade Limitada Unipessoal ou Empresa
            Individual)
          </p>

          <input
            {...register("companyName", { required: true })}
            type="text"
            placeholder="Razão social"
          />
          <input
            {...register("companyCnpj", { required: true })}
            type="text"
            placeholder="CNPJ"
          />
          <input
            {...register("companyRegister", { required: true })}
            type="text"
            placeholder="Número de registro de pessoa jurídica"
          />
        </div>

        <div className="form__divisor"></div>

        <div className="form__group">
          <p>
            <br />
            Venho requerer{" "}
            <strong>ISENÇÃO DE ANUIDADE DO EXERCÍCIO DE 2025</strong>, nos
            termos do artigo 7º, § 3º da{" "}
            <a
              href="https://www.coffito.gov.br/nsite/?p=30151"
              style={{ color: "var(--secondary-color)" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Resolução COFFITO nº 598/2024
            </a>
            , por me enquadrar nos requisitos necessários, ou seja:
          </p>
          <div className="user_authorization">
            <input
              id="firstUserRequirement"
              {...register("firstUserRequirement", { required: true })}
              type="checkbox"
              value="eligible"
            />
            <label htmlFor="firstUserRequirement">
              Sou sócio de Sociedade Limitada Unipessoal ou Empresa Individual
              devidamente registrado junto ao Crefito-2, tendo direito à isenção
              da pessoa física, do exercício de 2025.
            </label>
          </div>
          <div className="user_authorization">
            <input
              id="secondUserRequirement"
              {...register("secondUserRequirement", { required: true })}
              type="checkbox"
              value="eligible"
            />
            <label htmlFor="secondUserRequirement">
              Estou solicitando esta isenção dentro do prazo estabelecido na
              Resolução citada acima (20/01/2025).
            </label>
          </div>
          <div className="user_authorization">
            <input
              id="thirdUserRequirement"
              {...register("thirdUserRequirement", { required: true })}
              type="checkbox"
              value="eligible"
            />
            <label htmlFor="thirdUserRequirement">
              Não possuo débitos em aberto nos registros de pessoa física e
              jurídica.
            </label>
          </div>
          <div className="user_authorization">
            <input
              id="firstUserAuthorization"
              {...register("firstUserAuthorization", { required: true })}
              type="checkbox"
              value="authorized"
            />
            <label htmlFor="firstUserAuthorization">
              Autorizo o envio de informações para meu e-mail.
            </label>
          </div>
          <div className="user_authorization">
            <input
              id="secondUserAuthorization"
              {...register("secondUserAuthorization", { required: true })}
              type="checkbox"
              value="authorized"
            />
            <label htmlFor="secondUserAuthorization">
              Declaro, para fins de direito, sob as penas da lei, que as
              informações apresentadas são verdadeiras e autênticas.
            </label>
          </div>
          <input id="submit" className="button" type="submit" value="Enviar" />
        </div>
      </form>
    </div>
  );
}
