import React from "react";
import { Link } from "react-router-dom";

import "./styles.css";

import imageDefaultThumb from "./images/tributes_thumb.png";

export default function TributesEntry({ name, title, href, imgURL }) {
  return (
    <Link className="publications_block tribute" to={href}>
      <div className="tribute-image">
        <img
          src={imgURL === null ? imageDefaultThumb : imgURL}
          alt={`Foto de ${name}`}
        />
      </div>
      <div className="tribute-content">
        <p className="title">{title}</p>
        <p className="name">{name}</p>
      </div>
    </Link>
  );
}
